import * as React from 'react';
import { Text, View } from 'react-native';

const AdMobInterstitial = () => (
  <View>
    <Text>AdMobInterstitial component not supported on the web</Text>
  </View>
);

export default AdMobInterstitial;
